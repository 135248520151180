// extracted by mini-css-extract-plugin
export var charts = "what-i-do-module--charts--qSEp6";
export var container = "what-i-do-module--container--hyZDd";
export var containerVisible = "what-i-do-module--container-visible--UQ+q0 what-i-do-module--container--hyZDd";
export var content = "what-i-do-module--content--Xc2Es";
export var info = "what-i-do-module--info--9Jvmf";
export var skillTitle = "what-i-do-module--skill-title--UVcvS";
export var skills = "what-i-do-module--skills--CwO9i";
export var skillsContainer = "what-i-do-module--skills-container--gjh5y";
export var text = "what-i-do-module--text--2u6oQ";
export var title = "what-i-do-module--title--EbzFX";